/* styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; 
@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');
@layer components {
  .btn-primary {
    @apply px-4 py-2 text-white rounded-md shadow-md ;
  }
}
/*
.player-overlay{
  background: linear-gradient(to bottom, transparent, rgb(20, 20, 20));  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
} */
.bg-modal{
  backdrop-filter: blur(5px); /* Ajusta el valor según la cantidad de desenfoque deseada */
  background: rgba(15, 14, 14, 0.8); /* Ajusta el valor de alfa para controlar la opacidad del fondo */
 }
.cryptoicons{
  max-width: 300px;
  margin:0 auto ;
}
 
.playlist{  
  overflow-y: scroll;
}
* {
  margin: 0;
  padding: 0;
} 
.glitch { 
  font-family: "Carter One", system-ui;   
  position: relative; 
} 
 
.widget{
  width:100%; 
  padding-top:56.25%;
  -ms-flex-preferred-size:auto;
  flex-basis:auto;
  position:absolute;
  background:-webkit-gradient(linear,left top,left bottom,color-stop(23.08%,rgba(0,0,0,0)),to(#000));
  background:-webkit-linear-gradient(top,rgba(0,0,0,0) 23.08%,#000 100%);
  background:-o-linear-gradient(top,rgba(0,0,0,0) 23.08%,#000 100%);
  background:linear-gradient(180deg,rgba(0,0,0,0) 23.08%,#000 100%);   
  -webkit-border-radius:5px;
  border-radius:5px;
  overflow:hidden
}
