  
 /* Estilos de las imágenes de las criptomonedas */
.crypto-image {
    transition: transform 0.2s; /* Agregar una transición suave */
}

/* Efecto de zoom al pasar el ratón */
.crypto-image:hover {
    cursor: pointer;
    transform: scale(1.3); /* Aumentar el tamaño en un 10% */
}
.bg-ng{
    background-image: linear-gradient(to top, #000000, #0d0003, #160004, #1d0004, #230003, #230003, #230003, #230003, #1d0004, #160004, #0d0003, #000000);
}
.bg-player {
    background: linear-gradient(to top, 
        rgba(0, 0, 0, 0.55), 
        rgba(13, 0, 3, 0.55), 
        rgba(22, 0, 4, 0.55), 
        rgba(29, 0, 4, 0.55), 
        rgba(35, 0, 3, 0.55), 
        rgba(35, 0, 3, 0.55), 
        rgba(35, 0, 3, 0.55), 
        rgba(35, 0, 3, 0.55), 
        rgba(29, 0, 4, 0.55), 
        rgba(22, 0, 4, 0.55), 
        rgba(13, 0, 3, 0.55), 
        rgba(0, 0, 0, 0.55)
    ); 
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

